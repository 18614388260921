import { ContactGoal, NewsletterService, UserType, UserService, ContactLang } from '@mysg-website/api-client/lib'
import { NewsLetterKind } from '@mysg-website/api-server/src/graphql/schema'
import React, { useState } from 'react'
import styled from 'styled-components'

import { useWindowWidth } from '../../../hooks/window-width'
import { useUserInformation } from '../../../hooks/forms/contact-english/user-information'
import { TCustomerTypes, TGoals, useProject } from '../../../hooks/forms/contact-english/project'

import ProgressBarDesktop from './progress-bar-desktop'
import ProgressBarMobile from './progress-bar-mobile'
import UserInformation from './user-information'
import Project from './project'
// Import Appointment from './appointment'
import Confirmation from './confirmation'

const stepNames = ['Your contact details', 'Your project', 'Confirmation']

const customerTypes: TCustomerTypes = [
  { key: UserType.Company, title: 'Company' },
  { key: UserType.PublicActor, title: 'Public organization' },
  { key: UserType.TrainingOrganization, title: 'Training organization' },
  { key: UserType.Independent, title: 'Freelance' },
]

const goals: TGoals = [
  { key: ContactGoal.Support, title: 'I am looking for consulting to digitize my trainings​' },
  { key: ContactGoal.Talk, title: 'I already have a digital learning project and to discuss about it​' },
  { key: ContactGoal.Tool, title: 'I am looking for a powerful digital training tool' },
  { key: ContactGoal.Partnership, title: 'I have a solution that might interest you (partnership, freelance)​' },
]

export default function ContactEnglishForm() {
  const windowWidth = useWindowWidth()
  const [currentStep, setCurrentStep] = useState(0)
  // Const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const {
    state: { lastName, firstName, email, phone, error: userInformationError },
    actions: { setLastName, setFirstName, setEmail, setPhone, submit: submitUserInformation },
  } = useUserInformation(goNext)

  const {
    state: { customerType, selectedGoals, message, attachment, newsletterSubscribed, error: projectError },
    actions: {
      setCustomerType,
      setSelectedGoals,
      setMessage,
      setAttachment,
      subscribeNewsletter,
      submit: submitProject,
    },
  } = useProject(customerTypes, goals, handleSubmitForm)

  function goNext() {
    window.scrollTo(0, 0)
    setCurrentStep(currentStep + 1)
  }

  function goBack() {
    window.scrollTo(0, 0)
    setCurrentStep(currentStep > 0 ? currentStep - 1 : currentStep)
  }

  // Function handleAppointmentClick() {
  //   // TODO:
  // }

  async function handleSubmitForm() {
    setLoading(true)
    const gclid = typeof localStorage !== 'undefined' ? localStorage.getItem('gclid') : null
    await NewsletterService.subscribe({
      email,
      kind: NewsLetterKind.CONTACT_EN,
      firstname: firstName,
      lastname: lastName,
    })
    await UserService.requestContact(
      {
        goals: goals.filter(({ key }) => selectedGoals[key]).map(({ key }) => key),
        firstName,
        lastName,
        email,
        phone,
        customerType,
        message: message || undefined,
        lang: ContactLang.En,
        googleAds: Boolean(gclid && gclid.length > 0),
      },
      attachment || undefined
    )
    if (gclid && typeof localStorage !== 'undefined') localStorage.removeItem('gclid')
    goNext()

    setLoading(false)
  }

  return (
    <Container>
      {windowWidth > 480 ? (
        <ProgressBarDesktop currentStep={currentStep} stepNames={stepNames.map((stepName) => stepName)} />
      ) : (
        <ProgressBarMobile currentStep={currentStep} stepNames={stepNames.map((stepName) => stepName)} />
      )}
      <FormContainer>
        {currentStep === 0 && (
          <UserInformation
            email={email}
            error={userInformationError}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            setEmail={setEmail}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPhone={setPhone}
            submit={submitUserInformation}
          />
        )}
        {currentStep === 1 && (
          <Project
            attachment={attachment}
            customerType={customerType}
            customerTypes={customerTypes}
            error={projectError}
            goals={goals}
            goBack={goBack}
            loading={loading}
            message={message}
            newsletterSubscribed={newsletterSubscribed}
            selectedGoals={selectedGoals}
            setAttachment={setAttachment}
            setCustomerType={setCustomerType}
            setMessage={setMessage}
            setSelectedGoals={setSelectedGoals}
            submit={submitProject}
            subscribeNewsletter={subscribeNewsletter}
          />
        )}
        {/* {currentStep === 2 && (
          <Appointment
            error={error}
            goBack={goBack}
            loading={loading}
            onAppointmentClick={handleAppointmentClick}
            onCallClick={handleCallClick}
          />
        )} */}
        {currentStep === 2 && <Confirmation />}
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 48px;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
`

const FormContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 96px;
  width: calc(100% - 240px);

  @media screen and (max-width: 480px) {
    padding-left: 0;
    width: 100%;
  }
`
