import React from 'react'
import styled from 'styled-components'

import SvgCheck from '../../svg/check'

interface IProps {
  stepNames: string[]
  currentStep: number
}

export default function ProgressBarDekstop({ stepNames, currentStep }: IProps) {
  return (
    <Container>
      {stepNames &&
        stepNames.map((stepName, index) => (
          <StepContainer
            checked={index < currentStep || (currentStep === index && currentStep === stepNames.length - 1)}
            current={index === currentStep}
            key={index}
          >
            {index > 0 && <Separator checked={index <= currentStep} />}
            <div>
              <p>{stepName}</p>
              <div>
                {(index < currentStep || (currentStep === index && currentStep === stepNames.length - 1)) && (
                  <SvgCheck />
                )}
              </div>
            </div>
          </StepContainer>
        ))}
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4px;
  width: 240px;
`

const StepContainer = styled.div<{ current: boolean; checked: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    & > p {
      color: ${({ current, theme }) => (current ? theme.palette.secondary.main : theme.palette.text.primary)};
      font-family: ${({ current }) =>
        current ? "'Proxima Nova Extrabold', sans-serif;" : "'Proxima Nova Regular', sans-serif;"};
      font-size: 14px;
      margin: 0;
      text-transform: uppercase;
    }

    & > div {
      align-items: center;
      background-color: ${({ checked, theme }) => (checked ? theme.palette.secondary.main : '#ffffff')};
      border: ${({ current, checked, theme }) =>
        current || checked ? `solid 3px ${theme.palette.secondary.main}` : `solid 3px ${theme.palette.info.main}`};
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      height: 24px;
      justify-content: center;
      margin-right: -12px;
      width: 24px;

      & > svg {
        height: 18px;
        width: 18px;
      }
    }
  }
`

const Separator = styled.div<{ checked: boolean }>`
  border-right: ${({ checked, theme }) =>
    checked ? `solid 3px ${theme.palette.secondary.main}` : `solid 3px ${theme.palette.info.main}`};
  height: 32px;
`
