import React from 'react'
import styled from 'styled-components'

import { IFormError } from '../../../types/custom'
import Button from '../../buttons/main'
import TextField from '../fields/text'

interface IProps {
  lastName: string
  firstName: string
  email: string
  phone: string
  error: IFormError
  setLastName: (value: string) => void
  setFirstName: (value: string) => void
  setEmail: (value: string) => void
  setPhone: (value: string) => void
  submit: () => void
}

export default function UserInformation({
  lastName,
  firstName,
  email,
  phone,
  error,
  setLastName,
  setFirstName,
  setEmail,
  setPhone,
  submit,
}: IProps) {
  return (
    <Container>
      <TitleContainer>
        <h6>Information</h6>
      </TitleContainer>
      <FieldsContainer>
        <div>
          <TextField
            error={error.fieldName === 'firstName'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
            placeholder="FIRST NAME *"
            value={firstName}
          />
          <ErrorMessage>{error.fieldName === 'firstName' ? error.value : ''}</ErrorMessage>
        </div>
        <div>
          <TextField
            error={error.fieldName === 'lastName'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
            placeholder="LAST NAME *"
            value={lastName}
          />
          <ErrorMessage>{error.fieldName === 'lastName' ? error.value : ''}</ErrorMessage>
        </div>
        <div>
          <TextField
            error={error.fieldName === 'email'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
            placeholder="E-MAIL *"
            value={email}
          />
          <ErrorMessage>{error.fieldName === 'email' ? error.value : ''}</ErrorMessage>
        </div>
        <div>
          <TextField
            error={error.fieldName === 'phone'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)}
            placeholder="PHONE *"
            value={phone}
          />
          <ErrorMessage>{error.fieldName === 'phone' ? error.value : ''}</ErrorMessage>
        </div>
      </FieldsContainer>
      <ButtonsContainer>
        <Button color="primary" onClick={submit} variant="contained" width="168px">
          Next
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  & > h6 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
    font-family: 'Proxima Nova Extrabold', sans-serif;
    margin: 0 0 16px 0;
  }
`

const FieldsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(50% - 18px);

    & > input {
      font-size: 14px;
      width: 100%;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  & > div:nth-child(2n) {
    margin-left: 36px;

    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }
`

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  width: 100%;

  & > button {
    :hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 0;

    & > button {
      width: 100%;
    }
  }
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.error.main};
  font-family: 'Proxima Nova Extrabold', sans-serif;
  height: 20px;
  margin: 8px 0;
`
