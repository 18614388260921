import { useState } from 'react'
import validator from 'validator'

import { IFormError } from '../../../types/custom'
import { removePlusFromPhoneNumber } from '../../../utils/string'

interface IState {
  lastName: string
  firstName: string
  email: string
  phone: string
  error: IFormError
}

interface IActions {
  setLastName: (value: string) => void
  setFirstName: (value: string) => void
  setEmail: (value: string) => void
  setPhone: (value: string) => void
  submit: () => void
}

export function useUserInformation(goNext: () => void): { state: IState; actions: IActions } {
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState<IFormError>({ fieldName: null, value: '' })

  function submit() {
    let errorFieldName = null
    let errorValue = ''

    if (!lastName) {
      errorFieldName = 'lastName'
      errorValue = 'Please write your last name​.'
    } else if (!firstName) {
      errorFieldName = 'firstName'
      errorValue = 'Please write your first name.'
    } else if (!email) {
      errorFieldName = 'email'
      errorValue = 'Please write your e-mail.'
    } else if (!validator.isEmail(email)) {
      errorFieldName = 'email'
      errorValue = 'Please check the e-mail​.'
    } else if (!phone) {
      errorFieldName = 'phone'
      errorValue = 'Please write your phone number.'
    } else if (!validator.isMobilePhone(removePlusFromPhoneNumber(phone), 'any')) {
      errorFieldName = 'phone'
      errorValue = 'Please check the phone number.'
    }

    setError({ fieldName: errorFieldName, value: errorValue })

    if (!errorFieldName) {
      goNext()
    }
  }

  return {
    state: {
      lastName,
      firstName,
      email,
      phone,
      error,
    },
    actions: {
      setLastName,
      setFirstName,
      setEmail,
      setPhone,
      submit,
    },
  }
}
