import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplateContactEnglishDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import LayoutEnglish from '../../layouts/english'
import ContactEnglishForm from '../../components/forms/contact-english'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import { transformSeo } from '../../utils/seo-transformer'

import { transformContactEnglishNode } from './transformer'

interface IProps {
  data: TemplateContactEnglishDataQuery
  path?: string
}

export default function ContactEnglish({ data: { prismicContactEnglish }, path }: IProps) {
  const pageContent = transformContactEnglishNode(prismicContactEnglish)
  const seoContent =
    prismicContactEnglish &&
    prismicContactEnglish.data &&
    prismicContactEnglish.data.seo &&
    prismicContactEnglish.data.seo[0]
      ? transformSeo(prismicContactEnglish.data.seo[0])
      : undefined

  const { uid, name, title, description } = pageContent

  return (
    <LayoutEnglish path={path} seoContent={seoContent} title={getTitle(name)}>
      <Container>
        <Breadcrumb
          links={[
            { label: 'HOME', url: linkGenerator(PrismicDocumentType.HOME_ENGLISH) },
            { label: name, url: linkGenerator(PrismicDocumentType.PAGE, uid) },
          ]}
        />
        <h2>{title}</h2>
        <p>{description}</p>
        <ContactEnglishForm />
      </Container>
    </LayoutEnglish>
  )
}

export const query = graphql`
  query TemplateContactEnglishData {
    prismicContactEnglish {
      uid
      data {
        parentdocument {
          uid
          document {
            ... on PrismicHomeEnglish {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        name {
          text
        }
        title {
          text
        }
        description {
          text
        }
        seo {
          ... on PrismicContactEnglishSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
  }
`

ContactEnglish.query = query

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0 0 24px 0;
    max-width: 630px;
  }
`
